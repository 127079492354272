// App.js

import React from 'react';
import Header from './comp/heder/header';
import SearchBar from './comp/serch/SearchBar';
import './index.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import BlogList from './blogs/BlogList'; // Adjust the import path based on your file structure
import BlogDetail from './blogid/BlogDetail'; // Adjust the import path based on your file structure

const App = () => {
  return (
    <Router>
      <div className="App">
        <Header />
        <SearchBar />
        <Routes>
          <Route path="/" element={<BlogList />} />
          <Route path="/blog/:id" element={<BlogDetail />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
