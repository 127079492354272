import { useState } from "react";
import "./header.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

const Header = () => {
  const [showModel, setshowModel] = useState(false);
  return (
    <header className="flex">
  <button
  onClick={() => {
    setshowModel(true);
  }}
  className="menu"
>
  <FontAwesomeIcon icon={faBars} />
</button>

      <div className="logo">
        <img
          src="https://blogger.googleusercontent.com/img/a/AVvXsEgmtQV4VEnoedDBHNH81QuCk4qtrlh6jFJzNmUxf8Z9ZK26MNrHB9ekwCvsd18iQMQ7KrybExY1FhIbTG6u7ADTr2mosxcNcDxq_y8Hx1ubAEkKS-8z9gChugQlF04ic35wl2SemWprR-BCe0Rx2E2XNeBdXQygupmszq-bsiqAzeTS7osYUeVnNtTZNjg"
          alt="Description of the image"
        />
      </div>
      <nav>
        <ul className="flex">
          <li>
            <a href="https://sysbr.000.pe/#home">home</a>
          </li>
          <li>
            <a href="http://blog.sysbr.000.pe/">blog</a>
          </li>
          <li>
            <a href="https://sysbr.000.pe/#about">about</a>
          </li>
          <li>
            <a href="https://sysbr.000.pe/#contact">contact</a>
          </li>
          <li>
            <a href="https://sysbr.000.pe/#project">project</a>
          </li>
        </ul>
      </nav>

      <button className="icon-moon-o"></button>

      {showModel && (
        <div
          className="fixed border"
          onClick={() => {
            setshowModel(false);
          }}
        >
          <ul className="model border">
            <li>
              <button
                className="icon-cross"
                onClick={() => {
                  setshowModel(false);
                }}
              />
            </li>
            <li>
              <a href="https://sysbr.000.pe/#home">home</a>
            </li>
            <li>
            <a href="http://blog.sysbr.000.pe/">blog</a>
            </li>
            <li>
              <a href="https://sysbr.000.pe/#about">about</a>
            </li>
            <li>
              <a href="https://sysbr.000.pe/#contact">contact</a>
            </li>
            <li>
              <a href="https://sysbr.000.pe/#project">project</a>
            </li>
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;
