import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './searchbar.css';

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);

      try {
        const response = await axios.get('https://apii-8oq5.onrender.com/api/blogs.json');
        const blogsData = response.data;

        if (searchQuery.trim() === '') {
          setFilteredBlogs([]);
        } else {
          const filtered = blogsData.filter(blog =>
            blog.name.toLowerCase().includes(searchQuery.toLowerCase())
          );
          setFilteredBlogs(filtered);
        }
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setFilteredBlogs([]);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, [searchQuery]);

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleSelectBlog = (blogId) => {
    navigate(`/blog/${blogId}`);
  };

  return (
    <div className="search-container  ">
      <h2>Search Blogs</h2>
      <div className="search-bar ">
        <input
          type="text"
          placeholder="Search blogs..."
          value={searchQuery}
          onChange={handleSearchInputChange}
          className="search-input"
        />
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul className="blog-items ">
          {filteredBlogs.length > 0 ? (
            filteredBlogs.map((blog) => (
              <li key={blog.id} className="blog-item  bor" onClick={() => handleSelectBlog(blog.id)}>
                <div className='blog-img'>
                <img src={blog.imageUrl} alt={blog.name} className="blog-image " />
                </div>
             
                <p className="blog-name bor title">{blog.name}</p>
                <p className="blog-description ">{blog.description}</p>
                <p className='date'>{blog.date}</p>
              </li>
            ))
          ) : (
            <p className="no-results bor"></p>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchBar;
