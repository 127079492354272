import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './BlogList.css'; // Ensure you import the CSS file

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('https://apii-8oq5.onrender.com/api/blogs.json');
        setBlogs(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div className="blog-list ">
      <h2>Blog List</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <ul>
          {blogs.map(blog => (
            <li className='bor' key={blog.id}>
              <Link to={`/blog/${blog.id}`}>
             
                <img src={blog.imageUrl} alt={blog.name} />
                <div className='blog-space'></div>
                <div className='blog-name title'>
                <h3 className='bor'>{blog.name}</h3>
                </div>
                <div className='blog-discription'>
                <p>{blog.description}</p>
                </div>
                <p className='date'>{blog.date} </p>
               
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default BlogList;
