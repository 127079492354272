import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import './blogdetail.css';

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [blogHtmls, setBlogHtmls] = useState([]);
  const [blogInner, setBlogInner] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`https://apii-8oq5.onrender.com/api/blogs/${id}.json`);
        setBlog(response.data);
      } catch (error) {
        console.error('Error fetching blog details:', error);
        setBlog(null);
      }
    };

    const fetchBlogHtmls = async () => {
      try {
        const response = await axios.get(`https://apii-8oq5.onrender.com/api/Bloghtml/${id}.json`);
        setBlogHtmls(response.data || []);
      } catch (error) {
        console.error('Error fetching blog HTML content:', error);
        setBlogHtmls([]);
      } finally {
        setLoading(false);
      }
    };

    const fetchBlogInner = async () => {
      try {
        const response = await axios.get(`https://apii-8oq5.onrender.com/api/bloginner/${id}.json`);
        setBlogInner(response.data || []);
      } catch (error) {
        console.error('Error fetching blog inner content:', error);
        setBlogInner([]);
      }
    };

    fetchBlog();
    fetchBlogHtmls();
    fetchBlogInner();
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!blog) {
    return <p>Blog not found.</p>;
  }

  return (
    <div className="blog-detail-container bor">
      <p className="date  bor">{blog.date}</p>
      <h2 className="blog-title title bor">{blog.name}</h2>
   
      <img src={blog.imageUrl} alt={blog.name} className="blog-image" />
      <p className="blog-description">{blog.description}</p>
     
   
      {blogInner.length > 0 && (
        <div className="blog-inner-content">
          <ul>
            {blogInner.map((innerItem) => (
              <li key={innerItem.id}>
                <div className='blog-contenent'>
                  <img className='innerImg bor' src={innerItem.image} alt={innerItem.text} />
                  <div className='spacer'></div>
                  <div className='item-text bor'>
                    <p className='innerItem-text subtitle'>{innerItem.text}</p>
                  </div>
                </div>
                <div className='spacer'></div>
              </li>
            ))}
          </ul>
        </div>
      )}

      {blogHtmls.length > 0 && (
        <div className="blog-html-content">
          <div className='html-container bor'>
            {blogHtmls.map((htmlItem) => (
              <div key={htmlItem.id} className="scoped-html" dangerouslySetInnerHTML={{ __html: htmlItem.html }} />
            ))}
          </div>
        </div>
      )}

      <Link to="/" className="back-button">Back to Blog List</Link>
    </div>
  );
};

export default BlogDetail;
